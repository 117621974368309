$(document).ready(() => {
      // Single Ken Burns Image
  $('.inactiveUntilOnLoad').removeClass('inactiveUntilOnLoad');
  
  // Offset Fixed Navigation
  $('.nav-link, .mobile-links a, .btn-prime').click((e) => {
    const linkTarget = $(e.currentTarget).attr('href');

    if (linkTarget === '#' || linkTarget.charAt(0) !== '#') {
      return;
    }
    e.preventDefault();

    let topOffset = $(linkTarget).offset().top;
    let navContainer = '.fixed-top';
    if ($('.mobile-nav').is(':visible')) {
      if ($('#burger').is(':checked')) {
        navContainer = '.mobile-menu input~nav';
        $('#burger').trigger('click');
      }
    }

    topOffset -= $(navContainer).height();

    $('html, body').animate({
      scrollTop: topOffset,
    }, 900);
  });

  $('.logo-slider').slick({
    infinite: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToShow: 7,
    slidesToScroll: 4,
    responsive: [{
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        }
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          centerMode: true,
        }
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          centerMode: false,
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  });


  $('.events-slider').slick({
    arrows: false,
    infinite: true,
    dots: true,
    fade: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
  });


  $('.product-prev').click(() => {
    $('.events-slider').slick('slickPrev');
  });

  $('.product-next').click(() => {
    $('.events-slider').slick('slickNext');
  });


  // Wheel Click Modal
  $('button.btn-prime').on('mousedown', (e) => {
    if (e.which === 2) {
      e.preventDefault();
      e.stopPropagation();
      $(e.currentTarget).trigger('click');
    }
  });
});
